
import { Watch, Mixins, Component, Vue, Ref, Provide, ProvideReactive } from "vue-property-decorator";
import HomeProduct from "./component/homeProduct.vue";
import ProProduct from "./component/proProduct.vue";
import OtherProduct from "./component/otherProduct.vue";
import AddProductType from "./component/addProductType.vue";

import { deviceClassList } from "@/api/deviceType";

@Component({
  inject: [],
  components: {
    ProProduct,
    HomeProduct,
    OtherProduct,
    AddProductType,
  }
})
export default class ProductCategory extends Vue {
  @Ref("dialogForm") readonly dialogForm!: VForm;
  @Ref("ProProduct") readonly proProductRef!: ProProduct;
  @Ref("HomeProduct") readonly homeProductRef!: HomeProduct;
  @Ref("OtherProduct") readonly otherProductRef!: OtherProduct;
  @Ref("AddProductType") readonly addProductTypeRef!: AddProductType;

  @ProvideReactive() 
  pageCurrentTab = { label: "项目产品库", value: 1 };

  @ProvideReactive()
  merchantId = 0;

  keyword = "";
  currentTab = "1";
  dialogFormVisible = false;

  productTypeOptions = [{ label: "项目产品库", value: 1 }, { label: "居家产品库", value: 2 }, { label: "其他库", value: 3 }]

  @ProvideReactive()
  deviceTypeOptions: Array<{ deviceTypeId: number, deviceTypeName: string }> = [];


  formData = {
    id: "",
    parentId: undefined,
    name: "",
    sort: 1,
    categoryType: undefined,
    categoryTypeName: "",
    productRemark: "",
    status: true,
  }

  mounted() {
    this.getHealthType();
  }

  addCategory() {
    this.addProductTypeRef.dialogFormVisible = true;
  }


  getHealthType() {
    deviceClassList({}).then((res) => {
      this.deviceTypeOptions = res.data;
    })
  }

  refreshProductList() {
    if (this.currentTab == '1'){
      this.proProductRef?.getProProductList(1);
    }
    if (this.currentTab == '2'){
      this.homeProductRef?.getHomeProductList('2')
    }
    if (this.currentTab == '3'){
      this.otherProductRef?.getOtherProductList('3')
    }
  }

  @Watch("currentTab", { immediate: true })
  setProductType(value: string) {
    const categoryTypeInfo = this.productTypeOptions.find((item) => item.value == Number(value));
    this.formData.categoryTypeName = categoryTypeInfo.label;
    this.formData.categoryType = 1;
    this.formData.parentId = categoryTypeInfo.value;
    this.pageCurrentTab = this.productTypeOptions.find((item) => item.value ==  Number(value))
  }

}
